import React from 'react';
import atcLogo from '../images/andrew.svg';
import schLogo from '../images/evergreen.png';
import {Link} from 'react-router-dom';
import '../App.css';


function Nav () { 

  	return(
       <div className="row fixed-top full-width " >
    

      <div className="col-4 d-block d-sm-none standard-padding-tb text-center">
  
                <img src={schLogo} width="60" />
      

      </div>
       <div className="col-8 text-right standard-padding-tb d-block d-sm-none"> 
 
          <a className="navbar-brand col-8 paddingOff marginOff" href=""><img src={atcLogo} width="15" /></a>
 
          
    </div> 
      <nav className="navbar navbar-expand-lg navbar-light full-width" >
<div className="collapse navbar-collapse text-center" id="navbarSupportedContent">

  <a className="navbar-brand col-md-1" href="#"><img src={atcLogo} width="120" /></a>


  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>

  <ul className="navbar-nav col-10 text-center">
  <li className="col-md-3 offset-md-10 nav-item text-right">
   <img src={schLogo} width="120" />
 </li>
  </ul>
  </div>
</nav>
</div>



  	)
}



export default Nav

